import WINTER2023_LookbookImageCOVER from "../../Assets/Images/Main/wyredinlookbookcover.png";

import WINTER2023_LookbookImage1 from "../../Assets/Images/LookbookImages/Winter2023/1.png";
import WINTER2023_LookbookImage2 from "../../Assets/Images/LookbookImages/Winter2023/2.png";
import WINTER2023_LookbookImage3 from "../../Assets/Images/LookbookImages/Winter2023/3.png";
import WINTER2023_LookbookImage4 from "../../Assets/Images/LookbookImages/Winter2023/4.png";
import WINTER2023_LookbookImage5 from "../../Assets/Images/LookbookImages/Winter2023/5.png";
import WINTER2023_LookbookImage6 from "../../Assets/Images/LookbookImages/Winter2023/6.png";
import WINTER2023_LookbookImage7 from "../../Assets/Images/LookbookImages/Winter2023/7.png";
import WINTER2023_LookbookImage8 from "../../Assets/Images/LookbookImages/Winter2023/8.png";
import WINTER2023_LookbookImage9 from "../../Assets/Images/LookbookImages/Winter2023/9.png";
import WINTER2023_LookbookImage10 from "../../Assets/Images/LookbookImages/Winter2023/10.png";
import WINTER2023_LookbookImage11 from "../../Assets/Images/LookbookImages/Winter2023/11.png";

import WINTER2023_PreviewImage1 from "../../Assets/Images/LookbookImages/Winter2023/PreviewImages/1.png";
import WINTER2023_PreviewImage2 from "../../Assets/Images/LookbookImages/Winter2023/PreviewImages/2.png";
import WINTER2023_PreviewImage3 from "../../Assets/Images/LookbookImages/Winter2023/PreviewImages/3.png";
import WINTER2023_PreviewImage4 from "../../Assets/Images/LookbookImages/Winter2023/PreviewImages/4.png";
import WINTER2023_PreviewImage5 from "../../Assets/Images/LookbookImages/Winter2023/PreviewImages/5.png";
import WINTER2023_PreviewImage6 from "../../Assets/Images/LookbookImages/Winter2023/PreviewImages/6.png";
import WINTER2023_PreviewImage7 from "../../Assets/Images/LookbookImages/Winter2023/PreviewImages/7.png";

export const LookbookData = [
  {
    id: 0,
    title: "WINTER 2023",
    lookbookImages: [
      WINTER2023_LookbookImageCOVER,
      WINTER2023_LookbookImage1,
      WINTER2023_LookbookImage2,
      WINTER2023_LookbookImage3,
      WINTER2023_LookbookImage4,
      WINTER2023_LookbookImage5,
      WINTER2023_LookbookImage6,
      WINTER2023_LookbookImage7,
      WINTER2023_LookbookImage8,
      WINTER2023_LookbookImage9,
      WINTER2023_LookbookImage10,
      WINTER2023_LookbookImage11,
    ],
    lookBookPreviewImages: [
      WINTER2023_PreviewImage1,
      WINTER2023_PreviewImage2,
      WINTER2023_PreviewImage3,
      WINTER2023_PreviewImage4,
      WINTER2023_PreviewImage5,
      WINTER2023_PreviewImage6,
      WINTER2023_PreviewImage7,
    ],
  },
];
