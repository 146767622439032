import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ImageCarousel.css";

const ImageCarousel = ({ data }) => {
  return (
    <div className="ImageCarousel">
      <Carousel
        emulateTouch={true}
        infiniteLoop={true}
        renderIndicator={(clickHandler, isSelected, index) => {
          return (
            <li
              onClick={clickHandler}
              className={`ind ${isSelected ? "active" : ""}`}
              key={index}
              role="button"
            />
          );
        }}
        statusFormatter={() => {
          return null;
        }}
      >
        {data.map((val, key) => {
          return (
            <div key={key}>
              <img src={val} alt="LookbookImage" />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
