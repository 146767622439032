import "./Footer.css";
import WyredInLogo from "../../Assets/Images/wyredin.png";

import InstaLogo from "../../Assets/Images/Logos/insta.png";
import TwitterLogo from "../../Assets/Images/Logos/twitter.png";
import TikTokLogo from "../../Assets/Images/Logos/tiktok.png";
import YtLogo from "../../Assets/Images/Logos/yt.png";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="FooterWrapper">
        <div className="WyredInContainer">
          <img className="WyredInLogo" src={WyredInLogo} alt="logo" />
        </div>
        <div className="Logos">
          <Link to={"https://www.instagram.com/wyred.in/"}>
            <div className="LogoImageContainer">
              <img
                className="SocialLogo"
                src={InstaLogo}
                alt="Instagram Logo"
              />
            </div>
          </Link>
          <Link to={"https://twitter.com/wyred_in"}>
            <div className="LogoImageContainer">
              <img
                className="SocialLogo"
                src={TwitterLogo}
                alt="Twitter Logo"
              />
            </div>
          </Link>
          <Link to={"https://www.tiktok.com/@wyred.in"}>
            <div className="LogoImageContainer">
              <img className="SocialLogo" src={TikTokLogo} alt="Tiktok Logo" />
            </div>
          </Link>
          <Link to={"https://www.youtube.com/@wyredin"}>
            <div className="LogoImageContainer">
              <img className="SocialLogo" src={YtLogo} alt="YT Logo" />
            </div>
          </Link>
        </div>
        <div className="UnderText">
          <p>wyredinwyatt@gmail.com</p>
        </div>
        <div className="Credits">
          <h5>Site by Cole Sladowsky</h5>
        </div>
      </div>
    </div>
  );
};

export default Footer;
