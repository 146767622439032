import React from "react";
import Navbar from "../../Components/Top/Navbar";
import { LookbookData } from "./LookbookData";
import { useState } from "react";
import ImageCarousel from "../../Components/Carousel/ImageCarousel";
import "./LookbookCSS.css";
import Footer from "../../Components/Footer/Footer";
import Popup from "../../Components/PopupMenu/Popup";

const Lookbook = () => {
  const [active, setActive] = useState(LookbookData[0].id);
  const [currentLookbookOpen, setCurrentLookbookOpen] = useState(
    LookbookData[0].id
  );
  const [lookbookPreviewOpen, setLookbookPreviewOpen] = useState(false);

  const openLookBook = (lookBookID) => {
    setCurrentLookbookOpen(lookBookID);
    setLookbookPreviewOpen(false);
  };

  const openPreview = (lookBookID) => {
    setCurrentLookbookOpen(lookBookID);
    setLookbookPreviewOpen(true);
  };

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="LookbookPage">
      <Navbar />
      <div className="OpenMenuContainer">
        <button
          className="OpenMenuButton"
          onClick={() => setMenuOpen(!menuOpen)}
          style={menuOpen ? { color: "black" } : { color: "#e1b6d9" }}
        >
          {!menuOpen ? "OPEN " : "CLOSE "}COLLECTIONS
        </button>
      </div>
      <div className="Wrapper">
        <Popup trigger={menuOpen}>
          <div className="SelectionBarWrapperInside">
            <ul className="SelectionBarList">
              {LookbookData.map((val, key) => {
                return (
                  <li
                    className="row"
                    key={key}
                    onClick={() => setActive(val.id)}
                  >
                    <div id="title" className="TitleContainer">
                      {val.title}
                    </div>
                    <div>
                      {active === val.id ? (
                        <div className="Inners">
                          <ul className="InnersList">
                            <li
                              className="InnersListItem"
                              onClick={() => openLookBook(val.id)}
                            >
                              LOOKBOOK
                            </li>
                            {Object.keys(val.lookBookPreviewImages).length !==
                            0 ? (
                              <li
                                className="InnersListItem"
                                onClick={() => openPreview(val.id)}
                              >
                                PREVIEW
                              </li>
                            ) : null}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </Popup>
        <div className="SelectionBar">
          <div className="SelectionBarWrapper">
            <ul className="SelectionBarList">
              {LookbookData.map((val, key) => {
                return (
                  <li
                    className="row"
                    key={key}
                    onClick={() => setActive(val.id)}
                  >
                    <div id="title" className="TitleContainer">
                      {val.title}
                    </div>
                    <div>
                      {active === val.id ? (
                        <div className="Inners">
                          <ul className="InnersList">
                            <li
                              className="InnersListItem"
                              onClick={() => openLookBook(val.id)}
                            >
                              LOOKBOOK
                            </li>
                            {Object.keys(val.lookBookPreviewImages).length !==
                            0 ? (
                              <li
                                className="InnersListItem"
                                onClick={() => openPreview(val.id)}
                              >
                                PREVIEW
                              </li>
                            ) : null}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="LookbookImageContainer">
            {LookbookData.map((val, key) => {
              return currentLookbookOpen === val.id && lookbookPreviewOpen ? (
                val.lookBookPreviewImages.map((val, key) => {
                  return (
                    <div key={key} className="PreviewImageList">
                      <img className="PreviewImage" src={val} alt="" />
                    </div>
                  );
                })
              ) : currentLookbookOpen === val.id && !lookbookPreviewOpen ? (
                <ImageCarousel data={val.lookbookImages} />
              ) : null;
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Lookbook;
