import Home from "./Pages/Home";
import Lookbook from "./Pages/Lookbook/Lookbook";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  /* Navigate */
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/lookbook" element={<Lookbook />} />
      </Routes>
    </Router>
  );
}

export default App;
