import "./Popup.css";

const Popup = (props) => {
  return props.trigger ? (
    <div className="popup">
      <div className="popup-inner">
        {/*<button className="close-button">
          <CloseIcon />
  </button> */}
        {props.children}
      </div>
    </div>
  ) : null;
};

export default Popup;
