import "./Home.css";
/* import wyredinVideo from "../Assets/Videos/wyredin_trailer_website.mp4"; */
import Footer from "../Components/Footer/Footer";
import wyredin from "../Assets/Images/wyredin.png";
import { Link } from "react-router-dom";
import wyredinShoppingCartGIF from "../Assets/Images/wyattshoppingcart.gif";
import WyredInWinter2023 from "../Assets/Images/Main/wyredinlookbookcover.png";

const Home = () => {
  return (
    <div>
      <div className="Top">
        <div>
          <img className="MainTitleImage" src={wyredin} alt="Wyred In Logo" />
        </div>
      </div>
      <div className="Buttons">
        <div className="MainButtonContainer">
          <Link className="Button shop" to={"https://wyredin.bigcartel.com"}>
            <img
              className="ShoppingCartGIF"
              src={wyredinShoppingCartGIF}
              alt="im"
            />
            <p className="ButtonText">shop</p>
          </Link>
        </div>
        <div className="MainButtonContainer">
          <li className="Button dropdown contact">
            <p className="ButtonText">contact</p>
            <ul className="dropdown_menu dropdown_menu--animated contact">
              <li className="dropdownItem">
                <Link
                  className="itemLink"
                  to={"https://www.instagram.com/wyred.in/"}
                  target="_blank"
                >
                  instagram
                </Link>
              </li>
              <li className="dropdownItem">
                <Link
                  className="itemLink"
                  to={"https://twitter.com/wyred_in"}
                  target="_blank"
                >
                  twitter
                </Link>
              </li>
              <li className="dropdownItem">
                <Link
                  className="itemLink"
                  to={"https://www.tiktok.com/@wyred.in"}
                  target="_blank"
                >
                  tiktok
                </Link>
              </li>
              <li className="dropdownItem">
                <Link
                  className="itemLink"
                  to={"https://www.youtube.com/@wyredin"}
                  target="_blank"
                >
                  youtube
                </Link>
              </li>
            </ul>
          </li>
        </div>
        <div className="MainButtonContainer">
          <Link className="Button lookbook" to={"/lookbook"}>
            <p className="ButtonText">lookbook</p>
          </Link>
        </div>
      </div>
      <div className="Home">
        <div className="ImageContainer">
          {/*<video className="MainImage" type="video/mp4" autoPlay loop muted>
          <source src={wyredinVideo} />
  </video>*/}
          <img
            className="MainImage"
            src={WyredInWinter2023}
            alt="wyred in main"
          />
          <div className="WyredIn-trailer">
            <iframe
              src="https://www.youtube.com/embed/6bqA6F8sPvc"
              title="WYRED IN WINTER 2023 COLLECTION TRAILER"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
        </div>
      </div>
      <div></div>
      <Footer />
    </div>
  );
};

export default Home;
