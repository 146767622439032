import "./Navbar.css";
import wyredin from "../../Assets/Images/wyredin.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="Navbar">
      <Link to={"/"}>
        <img className="TitleImage" src={wyredin} alt="Wyred In Logo" />
      </Link>
    </div>
  );
};

export default Navbar;
